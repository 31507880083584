@import './onfido_styles.scss';
@import './unsupported_browser.scss';

:root {
  --text-color: black;
  --black-fade: #1e1e24;
  --blue: #06307c;
  --seablue: #0066cc;
  --input-blue: #0000ff;
  --green: #278836;
  --green-lighter: #43a752;
  --green-darker: #1e6228;
  --green-fade: #c2ebc8;
  --gray-one: #ebebeb;
  --gray-two: #9b9b9b;
  --gray-three: #4d4f53;
  --gray-four: #acacac;
  --gray-five: #757575;
  --gray-six: #97999b;
  --red: #ff2828;
  --red-fade: #ffc1c1;
  --red-dark: #a01a1a;
  --white-fade: #f7f8f9;
}

@font-face {
  font-family: 'BentonSans';
  src: url('../assets/fonts/BentonSans-Medium.eot');
  src:
    url('../assets/fonts/BentonSans-Medium.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/BentonSans-Medium.woff2') format('woff2'),
    url('../assets/fonts/BentonSans-Medium.woff') format('woff'),
    url('../assets/fonts/BentonSans-Medium.ttf') format('truetype'),
    url('../assets/fonts/BentonSans-Medium.svg#BentonSans-Medium') format('svg');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'BentonSans';
  src: url('../assets/fonts/BentonSans-Light.eot');
  src:
    url('../assets/fonts/BentonSans-Light.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/BentonSans-Light.woff2') format('woff2'),
    url('../assets/fonts/BentonSans-Light.woff') format('woff'),
    url('../assets/fonts/BentonSans-Light.ttf') format('truetype'),
    url('../assets/fonts/BentonSans-Light.svg#BentonSans-Light') format('svg');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'BentonSans';
  src: url('../assets/fonts/BentonSans-Regular.eot');
  src:
    url('../assets/fonts/BentonSans-Regular.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/BentonSans-Regular.woff2') format('woff2'),
    url('../assets/fonts/BentonSans-Regular.woff') format('woff'),
    url('../assets/fonts/BentonSans-Regular.ttf') format('truetype'),
    url('../assets/fonts/BentonSans-Regular.svg#BentonSans-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
}

body {
  margin: 0px;
  padding: 0px;
  font-family: 'BentonSans', sans-serif;
  font-size: 15px;
  line-height: 18px;
  color: var(--text-color);
}

.app {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: auto;
}
