.steps {
  margin: 0px auto;
  margin-top: 54px;
  margin-bottom: 40px;
  display: flex;
  position: relative;
  width: 400px;
  height: 100px;
  justify-content: space-between;

  &__wrapper {
    margin: 0 auto;
    width: 100%;
    display: inline-block;
  }

  &__wrapper .withApplication {
    width: 468px;
  }

  &--hidden {
    display: none;
  }
}

.step {
  display: flex;
  flex-direction: column;
  width: 156px;
  text-align: center;

  &::after {
    position: absolute;
    top: calc(28% - 1px);
    right: 13%;
    z-index: 1;
    width: 40%;
    height: 1px;
    content: ' ';
    background-color: var(--gray-two);
  }

  &.noApplication::after {
    width: 65%;
  }

  &.step-1::after {
    display: none;
  }

  &.step-2::after {
    right: 45%;
  }

  &.step-2.noApplication::after {
    right: 20%;
  }

  &.active {
    .step__label {
      font-weight: 500;
    }
  }

  &__count {
    margin: 0 auto;
    width: 48px;
    height: 48px;
    z-index: 2;
    border-radius: 48px;
    border: solid 2px var(--green);
    background-color: white;
    color: var(--green);
    text-align: center;
    line-height: 48px;
    font-size: 22px;
    font-weight: 500;
  }

  &__auto-height {
    height: auto;
  }

  &.done {
    &::after {
      background-color: var(--green);
    }
    .step__count {
      background-color: var(--green);
      color: white;
    }

    .icon-step-done {
      display: inline-block;
      width: 100%;
      height: 100%;
      background-position: center center;
      background-repeat: no-repeat;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3E%3Cpath fill='%23F9F9F9' fill-rule='evenodd' d='M11.208 21.6l-6.095-6.454c-.71-.752-1.898-.786-2.65-.075-.753.71-.787 1.897-.076 2.65l7.5 7.941c.755.8 2.034.78 2.764-.041l15-16.875c.688-.774.619-1.96-.155-2.647-.774-.688-1.96-.619-2.647.155L11.209 21.6z'/%3E%3C/svg%3E%0A");
    }
  }

  &.active {
    &::after {
      background-color: var(--green);
    }
  }

  &.disabled {
    .step__count {
      border-color: var(--gray-one);
      background-color: var(--gray-one);
      color: var(--gray-three);
    }
  }

  &__label {
    width: 100%;
    margin-top: 12px;
    text-align: center;
  }

  a {
    width: 100%;
    font-size: 13px;
    color: #0000ee;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

.modal--onfido-steps,
.modal--idin-steps {
  text-align: center;

  img {
    margin-top: 10px;
    width: 156px;
  }

  p {
    margin-top: 30px;
    text-align: left;
  }
}

.modal--idin-steps {
  img {
    width: 69px;
  }
}

@media (max-width: 646px) {
  .steps__wrapper .withApplication {
    width: 100%;
  }

  .steps {
    width: 100%;
  }

  .step {
    width: 33%;

    &__label {
      font-size: 14px;
    }
  }
}

@media (max-width: 375px) {
  .step {
    width: 33%;

    &__label,
    a {
      font-size: 12px;
    }
  }
}
