.select-input {
  width: 290px;
  border: 1px solid var(--gray-two);
  border-radius: 4px;
  font-size: 16px;
  box-sizing: border-box;
  position: relative;

  &--focus {
    border-color: var(--input-blue);
  }

  .select-input__value {
    padding: 10px;
    cursor: pointer;
  }

  .select-input__arrow {
    float: right;
    width: 11px;
    height: 7px;
    background-repeat: no-repeat;
    background-position: top left;
    margin: 7px 3px 0 0;

    &--up {
      background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='11px' height='7px' viewBox='0 0 11 7' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3C!-- Generator: Sketch 61.2 (89653) - https://sketch.com --%3E%3Ctitle%3EPath%3C/title%3E%3Cdesc%3ECreated with Sketch.%3C/desc%3E%3Cg id='Symbols' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Organism-/-Desktop-/-IDIN-default-/-Steps-/-BOX-Copy' transform='translate(-759.000000, -419.000000)' fill='%23000000' fill-rule='nonzero'%3E%3Cpolygon id='Path-3' points='768.423546 419 770.057732 420.757679 764.528866 425.898092 759 420.757679 760.634186 419 764.528093 422.61984'%3E%3C/polygon%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
    }

    &--down {
      background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='11px' height='8px' viewBox='0 0 11 8' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3C!-- Generator: Sketch 61.2 (89653) - https://sketch.com --%3E%3Ctitle%3EPath%3C/title%3E%3Cdesc%3ECreated with Sketch.%3C/desc%3E%3Cg id='Symbols' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Organism-/-Desktop-/-IDIN-default-/-Steps-/-BOX-Copy' transform='translate(-759.000000, -425.000000)' fill='%23000000' fill-rule='nonzero'%3E%3Cpolygon id='Path' transform='translate(764.528866, 429.347138) scale(1, -1) translate(-764.528866, -429.347138) ' points='768.423546 425.898092 770.057732 427.655771 764.528866 432.796184 759 427.655771 760.634186 425.898092 764.528093 429.517932'%3E%3C/polygon%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
    }
  }

  .select-input__list {
    position: absolute;
    top: 37px;
    left: -1px;
    box-sizing: border-box;
    list-style: none;
    padding: 10px;
    margin: 0px;
    width: 290px;
    max-height: 267px;
    overflow: hidden;
    overflow-y: auto;
    background-color: white;
    border: 1px solid var(--input-blue);
    border-top: 1px solid var(--gray-two);
    border-radius: 0 0 4px 4px;

    &::-webkit-scrollbar-track {
      border-radius: 10px;
      background-color: #f5f5f5;
    }

    &::-webkit-scrollbar {
      width: 6px;
      background-color: #f5f5f5;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: var(--gray-four);
    }

    li {
      font-size: 10px;
      color: var(--gray-six);

      ul {
        list-style: none;
        margin: 6px 0 0 0;
        padding: 0px;

        li {
          cursor: pointer;
          box-sizing: border-box;
          font-size: 16px;
          color: var(--text-color);
          padding: 6px 18px;
          margin: 6px 0;

          &:hover {
            background-color: var(--white-fade);
          }
        }
      }
    }
  }
}

@media (max-width: 646px) {
  .select-input {
    width: 100%;
  }
}
