.help {
  text-align: center;
  font-size: 13px;
  line-height: 18px;
  color: var(--gray-three);
  width: 430px;
  margin: 0 auto;
}

@media (max-width: 476px) {
  .help {
    width: 100%;
    box-sizing: border-box;
    padding: 0 10px;
  }
}
