.suggest-input {
  &__wrapper {
    display: inline-block;
    width: 100%;
    position: relative;
    box-sizing: border-box;

    &.focused {
      input[type='text'] {
        outline: none;
        border: 1px solid #0000ee;
        -webkit-user-select: text; /* Chrome, Opera, Safari */
        -moz-user-select: text; /* Firefox 2+ */
        -ms-user-select: text; /* IE 10+ */
        user-select: text; /* Standard syntax */
      }

      .suggest-input__search,
      .suggest-input__autosuggest {
        border: 1px solid #0000ee;
        border-top: 1px solid var(--gray-four);
      }
    }

    .search-icon {
      position: absolute;
      top: 11px;
      left: 11px;
    }

    .clear-input {
      position: absolute;
      right: 14px;
      top: 14px;
      display: inline-block;
      width: 11px;
      height: 12px;
      background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='11px' height='12px' viewBox='0 0 11 12' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3C!-- Generator: Sketch 64 (93537) - https://sketch.com --%3E%3Ctitle%3EPath%3C/title%3E%3Cdesc%3ECreated with Sketch.%3C/desc%3E%3Cg id='Symbols' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cpolygon id='Path' fill='%23757575' fill-rule='nonzero' points='8.16171442 0.926281694 10.2868986 3.04373118 7.24027525 6.10028169 10.2980248 9.14672086 8.18057531 11.271905 5.12327525 8.22528169 2.07758563 11.2830312 -0.0475985128 9.16558175 2.99827525 6.10828169 -0.0587247451 3.06259207 2.05872475 0.937407927 5.11527525 3.98328169'%3E%3C/polygon%3E%3C/g%3E%3C/svg%3E");
      cursor: pointer;

      &:hover {
        background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='11px' height='12px' viewBox='0 0 11 12' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3C!-- Generator: Sketch 64 (93537) - https://sketch.com --%3E%3Ctitle%3EPath%3C/title%3E%3Cdesc%3ECreated with Sketch.%3C/desc%3E%3Cg id='Symbols' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cpolygon id='Path' fill='%23278836' fill-rule='nonzero' points='8.16171442 0.926281694 10.2868986 3.04373118 7.24027525 6.10028169 10.2980248 9.14672086 8.18057531 11.271905 5.12327525 8.22528169 2.07758563 11.2830312 -0.0475985128 9.16558175 2.99827525 6.10828169 -0.0587247451 3.06259207 2.05872475 0.937407927 5.11527525 3.98328169'%3E%3C/polygon%3E%3C/g%3E%3C/svg%3E");
      }
    }

    input[type='text'] {
      box-sizing: border-box;
      width: 100%;
      height: 40px;
      padding-left: 35px;
      font-size: 16px;
      border-radius: 5px;
      border: 1px solid var(--gray-four);
    }

    .suggest-input__suggest-label {
      position: absolute;
      z-index: 2;
      left: 12px;
      top: 43px;
      font-size: 10px;
    }
  }

  &__search,
  &__autosuggest {
    box-sizing: border-box;
    position: absolute;
    bottom: -185px;
    left: 0;
    width: 100%;
    height: 188px;
    list-style: none;
    margin: 0px;
    padding: 0px 5px 5px;
    border: 1px solid var(--gray-four);
    background-color: white;
    overflow: hidden;
    overflow-y: hidden;
    border-radius: 0 0 5px 5px;

    &::-webkit-scrollbar-track {
      border-radius: 10px;
      background-color: #f5f5f5;
    }

    &::-webkit-scrollbar {
      width: 6px;
      background-color: #f5f5f5;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: var(--gray-four);
    }

    li {
      margin: 8px 0;
      padding: 8px 6px;
      cursor: pointer;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden !important;

      &:hover {
        background-color: #f7f8f9;
      }
      span {
        margin-right: 10px;
        font-size: 16px;
      }
    }

    &--no-results {
      height: 58px;
      bottom: -55px;

      li {
        margin-top: 12px;

        &:hover {
          cursor: auto;
          background-color: transparent;
        }
      }
    }
  }

  &__autosuggest {
    padding: 14px 5px 5px;
  }

  &__autosuggest {
    padding: 12px 5px 5px;
  }
}
