#outdated {
  font-family: 'BentonSans', sans-serif;
  position: absolute;
  background-color: white !important;
  color: black !important;
  display: none;
  overflow: hidden;
  left: 0;
  position: fixed;
  text-align: center;
  text-transform: uppercase;
  top: 0;
  width: 100%;
  z-index: 1500;
  padding: 30px;
}
#outdated.fullscreen {
  height: 100%;
}
#outdated .vertical-center {
  display: block !important;
  max-width: 410px !important;
  margin: 0px auto !important;
  border: 1px solid #9b9b9b;
  padding: 30px !important;
  text-align: center;
}
#outdated h6 {
  color: #1e1e24 !important;
  font-size: 25px;
  line-height: 25px;
  margin: 12px 0;
  text-transform: none !important;
  max-width: 470px;
}
#outdated p {
  color: #4d4f53 !important;
  font-size: 14px;
  line-height: 18px;
  margin: 0;
  text-transform: none !important;
  max-width: 470px;
}
#outdated #buttonUpdateBrowser {
  border: 2px solid white;
  color: white;
  cursor: pointer;
  display: block;
  margin: 30px auto 0;
  padding: 10px 20px;
  position: relative;
  text-decoration: none;
  width: 230px;
}
#outdated #buttonUpdateBrowser:hover {
  background-color: white;
  color: #f25648;
}
#outdated .last {
  height: 20px;
  position: absolute;
  right: 70px;
  top: 10px;
  width: auto;
  display: inline-table;
}
#outdated .last[dir='rtl'] {
  left: 25px !important;
  right: auto !important;
}
#outdated #buttonCloseUpdateBrowser {
  color: white;
  display: block;
  font-size: 36px;
  height: 100%;
  line-height: 36px;
  position: relative;
  text-decoration: none;
  width: 100%;
}
