$topbar-gradient-top: #fdfdfd;
$topbar-gradient-bottom: #dfe1e0;

.topbar {
  width: 100%;
  height: 70px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.5);
  background-image: linear-gradient(to bottom, $topbar-gradient-top, $topbar-gradient-bottom);

  a:focus {
    outline: none;
  }

  img {
    width: 55px;
    margin: 9px 0 0 170px;
  }
}

@media (max-width: 646px) {
  .topbar {
    text-align: left;

    img {
      margin: 0px auto;
      margin-top: 9px;
      margin-left: 20px;
    }
  }
}
