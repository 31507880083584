.footer {
  margin-top: auto;
  width: 100%;
  height: 156px;

  &__wrapper {
    margin-top: 72px;
    height: 82px;
    border-top: 1px solid var(--gray-two);
    background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 93%, rgba(0, 0, 0, 0.3) 100%);
    text-align: center;
  }

  &__menu {
    display: inline-block;
    width: 100%;
    list-style: none;
    padding: 0;
    margin: 24px 0 0;

    li {
      display: inline-block;
      margin-right: 8px;
      font-size: 12px;

      &::after {
        content: '|';
        padding-left: 8px;
        color: var(--gray-two);
      }

      &:last-child {
        margin-right: 0;

        &::after {
          content: '';
          padding: 0;
        }
      }

      a {
        text-decoration: none;
        color: var(--seablue);

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  &__copyright {
    font-size: 12px;
    margin-bottom: 15px;

    a {
      text-decoration: none;
      color: var(--seablue);

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

@media (max-width: 476px) {
  .footer {
    box-sizing: border-box;
    height: auto;
    margin-bottom: 20px;

    &__wrapper {
      padding: 0 10px 30px;
    }

    .footer__menu {
      margin-bottom: 10px;
    }
  }
}
